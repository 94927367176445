import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/about/Hero";
import Masonry from "../sections/about/Masonry";
import Content from "../sections/about/Content";

const PricingPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <button className="btn btn btn-blue-3 header-btn-2 font-size-3 rounded-5">
                Schrijf je nu in
              </button>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero />
        <Masonry />
        <Content />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
